import {
  StepSevenTypes,
  agreementTypes,
  stepFiveTypes,
  stepFourTypes,
  stepSixTypes,
  stepThreeTypes,
  stepTwoTypes,
} from "utils/types";
import * as Yup from "yup";

export const steponeSchema: Yup.Schema<{ studentId: string }> =
  Yup.object().shape({
    studentId: Yup.string()
      .required("studentId is required")
      .matches(/^[a-zA-Z0-9\s]+$/, "Please enter only digits and letters"),
  });

export const agreementSchema: Yup.Schema<agreementTypes> = Yup.object().shape({
  isDataprocess: Yup.boolean()
    .required("Please accept terms")
    .test("isDataprocess", "Please accept terms", (value) => value === true),
  isImmigration: Yup.boolean()
    .required("Please accept terms")
    .test("isImmigration", "Please accept terms", (value) => value === true),
  isAttorney: Yup.boolean()
    .required("Please accept terms")
    .test("isAttorney", "Please accept terms", (value) => value === true),
  signature: Yup.string().required("Signature is required"),
});

export const submitSchema: Yup.Schema = Yup.object().shape({
  // date : Yup.date().required("Date is required"),
  // signature: Yup.string().required("Signature is required"),
});

export const steptwoSchema: Yup.Schema<stepTwoTypes> = Yup.object().shape({
  lastName: Yup.string().test(
    "contains-letters",
    "At least one letter is required",
    (value: any) => {
      return /[a-zA-Z]/.test(value);
    }
  ),
  firstName: Yup.string()
    .required("First name is required")
    .test("contains-letters", "At least one letter is required", (value) => {
      return /[a-zA-Z]/.test(value);
    }),
  middleName: Yup.string().test(
    "contains-letters",
    "At least one letter is required",
    (value: any) => {
      return /[a-zA-Z]/.test(value);
    }
  ),
  gender: Yup.string().required("Gender is required"),
  city: Yup.string().required("City is required"),
  zipcode: Yup.string()
    .required("Zipcode is required")
    .matches(/^\d+$/, "Zipcode must contain only digits")
    .max(10, "Zipcode must be at most 10 characters"),
  address: Yup.string()
    .required("Address is required")
    .test(
      "contains-letters",
      "At least one letter is required",
      (value: any) => {
        return /[a-zA-Z]/.test(value);
      }
    ),
  houseNo: Yup.string().required("House number is required"),
  // phoneNumber: Yup.string()
  // .required("Phone number should not less than 7 digits")
  // .min(7, "Phone number should not less than 7 digits"),
  dob: Yup.date().required("Birthdate is required"),
  birthPlace: Yup.string().required("Birthplace is required"),
  birthCountry: Yup.string().required("Birthcountry is required"),
  region: Yup.string().required("Region is required"),
  addressRegister: Yup.string().required("Address register is required"),
  registerDate: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Register date is required",
    test: function (value) {
      return !!(this.parent.addressRegister === "Yes") ? !!value : true;
    },
  }),
  addressFile: Yup.mixed().test({
    name: "requiredWhenAddressRegister",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.addressRegister === "Yes" ? value.length : true;
      }
    },
  }),
});

export const stepthreeSchema: Yup.Schema<stepThreeTypes> = Yup.object().shape({
  file: Yup.array()
    .required("Passport file is required")
    .min(1, "Files are required")
    .max(20, "You cannot upload more than 20 files"),
  docType: Yup.string().required("Document type is required"),
  authority: Yup.string().required("Authority is required"),
  docNumber: Yup.string().required("Document Number is required"),
  issueDate: Yup.date().required("Issue date is required"),
  validDate: Yup.date().required("Valid date is required"),
});

export const stepFourSchema: Yup.Schema<stepFourTypes> = Yup.object().shape({
  stampFile: Yup.mixed()
    .test({
      name: "is-array-if-required",
      message: "Files are required",
      test: function (value: any) {
        if (value) {
          return value.length === 0 ? value.length : true;
        }
      },
    })
    .required("Files are required"),
  date: Yup.date().required("Date is required"),
  lastEntrycity: Yup.string().required("Country is required"),
});

export const stepFiveSchema: Yup.Schema<stepFiveTypes> = Yup.object().shape({
  citizinShip: Yup.string().required("Citizenship is required"),
  additionalCitizinShip: Yup.string().required(
    "Please select additional citizenship"
  ),
  // citizinShip1: Yup.string().test({
  //   name: "requiredWhenadditionalCitizinShip",
  //   message: "Please select at least one citizenship",
  //   test: function (value) {
  //     return !!(
  //       this.parent.additionalCitizinShip === "Yes" &&
  //       !this.parent.citizinShip2 &&
  //       !this.parent.citizinShip3
  //     )
  //       ? !!value
  //       : true;
  //   },
  // }),
});

export const stepSixSchema: Yup.Schema<stepSixTypes> = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  // lastEntry: Yup.string().test({
  //   name: "requiredWhenstatus",
  //   message: "Last entry date is required",
  //   test: function (value) {
  //     console.log("this.parent.status",this.parent.status)
  //     return !!(this.parent.status === "90-days free entry allowance") ? !!value : true;
  //   },
  // }),
  // lastExit: Yup.string().test({
  //   name: "requiredWhenstatus",
  //   message : "Last exit  date required",
  //   test: function (value) {
  //     return !!(this.parent.status === "90-days free entry allowance") ? !!value : true;
  //   },
  // }),
  // passportFile : Yup.mixed().test({
  //   name: "requiredWhenstatus",
  //   message: "Files are required",
  //   test: function (value: any) {
  //     if (value) {
  //       return this.parent.status === "90-days free entry allowance" ? value.length : true;
  //     }
  //   },
  // }),
});

export const stepSevenSchema: Yup.Schema<any> = Yup.object().shape({
  germanLastEntry: Yup.string().required("German last entry is required"),
  germanLastEntryFrom1: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.germanLastEntry === "Yes") ? !!value : true;
    },
  }),
  germanLastEntryTo1: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.germanLastEntry === "Yes") ? !!value : true;
    },
  }),
  germanLastEntryCity1: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "City is required",
    test: function (value) {
      return !!(this.parent.germanLastEntry === "Yes") ? !!value : true;
    },
  }),
  authorisation: Yup.string().required("Authorisation is required"),
  authCountry: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Country is required",
    test: function (value) {
      return !!(this.parent.authorisation === "Yes") ? !!value : true;
    },
  }),
  authDate: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.authorisation === "Yes") ? !!value : true;
    },
  }),
  studyprogramme: Yup.string().required("Please select your study program"),
  residence: Yup.string().required("Residence is required"),
  residenceCountry: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Country is required",
    test: function (value) {
      return !!(this.parent.residence === "Yes") ? !!value : true;
    },
  }),
  maritalStatus: Yup.string().required("Status is required"),
  widowedDate: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Widowed") ? !!value : true;
    },
  }),
  dom: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  mrgCity: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "City is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  mrgCountry: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Country is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseFname: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "First name is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseGender: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Gender is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseDob : Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spousePob : Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth place is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseCob : Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Country is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseCitizenship : Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Citizenship is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  spouseRescountry : Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Country is required",
    test: function (value) {
      return !!(this.parent.maritalStatus === "Married") ? !!value : true;
    },
  }),
  children: Yup.string().required("Please select one field"),
  father: Yup.string().required("Please select one field"),
  fatherLname: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Last name is required",
    test: function (value) {
      return !!(this.parent.father === "Yes") ? !!value : true;
    },
  }),
  fatherFname: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "First name is required",
    test: function (value) {
      return !!(this.parent.father === "Yes") ? !!value : true;
    },
  }),
  fatherInfo: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Information is required",
    test: function (value) {
      return !!(this.parent.father === "Yes") ? !!value : true;
    },
  }),
  fatherDob: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth date is required",
    test: function (value) {
      return !!(this.parent.fatherInfo === "Yes") ? !!value : true;
    },
  }),
  fatherBirthplcae: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth place is required",
    test: function (value) {
      return !!(this.parent.fatherInfo === "Yes") ? !!value : true;
    },
  }),
  fatherBirthCountry: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth country is required",
    test: function (value) {
      return !!(this.parent.fatherInfo === "Yes") ? !!value : true;
    },
  }),
  fatherCitizenship: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Citizenship is required",
    test: function (value) {
      return !!(this.parent.fatherInfo === "Yes") ? !!value : true;
    },
  }),
  mother: Yup.string().required("Please select one field"),
  motherLname: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Last name is required",
    test: function (value) {
      return !!(this.parent.mother === "Yes") ? !!value : true;
    },
  }),
  motherFname: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "First name is required",
    test: function (value) {
      return !!(this.parent.mother === "Yes") ? !!value : true;
    },
  }),
  motherInfo: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Information is required",
    test: function (value) {
      return !!(this.parent.mother === "Yes") ? !!value : true;
    },
  }),
  motherDob: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth date is required",
    test: function (value) {
      return !!(this.parent.motherInfo === "Yes") ? !!value : true;
    },
  }),
  motherBirthplcae: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth place is required",
    test: function (value) {
      return !!(this.parent.motherInfo === "Yes") ? !!value : true;
    },
  }),
  motherBirthCountry: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Birth country is required",
    test: function (value) {
      return !!(this.parent.motherInfo === "Yes") ? !!value : true;
    },
  }),
  motherCitizenship: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Citizenship is required",
    test: function (value) {
      return !!(this.parent.motherInfo === "Yes") ? !!value : true;
    },
  }),
  criminality: Yup.string().required("Please select one field"),
  germanyRejection: Yup.string().required("Please select one field"),
  otherRejection: Yup.string().required("Please select one field"),
  subsistence: Yup.string().required("Please select one field"),
  subsistenceFile: Yup.mixed().test({
    name: "requiredWhensubsistence",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.subsistence === "Yes" ? value.length : true;
      }
    },
  }),
  healthInsurance: Yup.string().required("Please select one field"),
  healthInsuranceFile: Yup.mixed().test({
    name: "requiredWhenhealthInsurance",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.healthInsurance === "Yes" ? value.length : true;
      }
    },
  }),
  matriculationLetter: Yup.string().required("Please select one field"),
  date: Yup.string().test({
    name: "requiredWhenMatriculationLetter",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.matriculationLetter === "Yes") ? !!value : true;
    },
  }),
  matriculationFile: Yup.mixed().test({
    name: "requiredWhenmatriculationLetter",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.matriculationLetter === "Yes" ? value.length : true;
      }
    },
  }),
});
