import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/Hooks";
import { cross } from "utils/Images";
import { setStudent } from "store/slices/User";
import { changeAppointmentStatusMenu, changeAttributesMenu, docs } from "utils/data";
import Select from "react-select";

type item = {
  id: string;
  value: string;
  hasSubmenu: boolean;
  route?: string;
};

type subItem = {
  id: string;
  value: string | string[];
};

interface ModalProps {
  student: any;
  handleClose: any;
  menuItems: item[];
  sendEmailMenu?: string[];
  sendReminderMenu?: string[];
  sendUniDocMenu?: string[];
  sendHsStudentMenu?: string[];
  isTemplate?: boolean;
  setCorrectionModal?: any;
  setSelectedStudentLEA?: any;
}

const ContextMenuModal = ({
  setSelectedStudentLEA,
  setCorrectionModal,
  isTemplate,
  student,
  menuItems,
  sendEmailMenu,
  sendReminderMenu,
  sendUniDocMenu,
  sendHsStudentMenu,
  handleClose
}: ModalProps) => {
  console.log("menuItems",menuItems)
  const dispatch = useAppDispatch();
  const navigate: any = useNavigate();
  const customStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: "none",
      padding: "0px !important",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "3px",
    }),
    input: (provided: any) => ({
      ...provided,
      width: "0px",
      fontSize: "16px",
      color: "#0B0D0F",
      fontFamily: {
        "martel-sans": ["Martel Sans", "sans-serif"],
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#0B0D0F",
      fontSize: "16px",
      fontFamily: {
        "martel-sans": ["Martel Sans", "sans-serif"],
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99999999, // Ensure the dropdown menu is above other elements
      margin: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 99999999, // Ensure the dropdown menu is above other elements
    }),
    option: (
      provided: { [x: string]: any },
      state: { isSelected: any; isFocused: any }
    ) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "transparent"
        : state.isFocused
        ? "transparent"
        : "transparent",
      color: "#0B0D0F",
      ":active": {
        ...provided[":active"],
        backgroundColor: "transparent",
      },
    }),
  };

  const renderSubmenu = useCallback(
    (name: string, value: string) => {
      switch (name) {
        case "appointmentStatus":
          return (
            <div className="w-full">
              <Select
                styles={customStyles}
                name="colors"
                options={changeAppointmentStatusMenu?.map((item) => {
                  return {
                    label: item?.value,
                    value: item?.value,
                  };
                })}
                className="basic-multi-select rounded-[10px]"
                isSearchable={true}
                placeholder={value}
                classNamePrefix="select"
                menuPortalTarget={document.body}
                onChange={(data) => {
                  console.log("data",data)
                  // dashboardService.updateAppointmentStatusById(student?._id,data?.id)
                }}
              />
            </div>
          );
        case "attributes":
          return (
            <div className="w-full">
              <Select
                styles={customStyles}
                name="colors"
                options={changeAttributesMenu?.map((item) => {
                  return {
                    label: item?.value,
                    value: item?.value,
                  };
                })}
                className="basic-multi-select rounded-[10px]"
                isSearchable={true}
                placeholder={value}
                classNamePrefix="select"
                menuPortalTarget={document.body}
                onChange={(data) => {
                  if (data?.value === "Correction Status") {
                    handleClose();
                    setSelectedStudentLEA(student);
                    setCorrectionModal && setCorrectionModal(true);
                  }
                }}
              />
            </div>
          );
        case "email":
          if (isTemplate) {
            return (
              <div className="w-full">
                <Select
                  styles={customStyles}
                  name="colors"
                  options={sendEmailMenu?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  className="basic-multi-select rounded-[10px]"
                  isSearchable={true}
                  placeholder={value}
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  onChange={(data) => {
                    navigate("/send-email");
                    dispatch(
                      setStudent({
                        ...student,
                        templateName:
                          data?.value === "Add new" ? null : data?.value,
                        templateFor: "Send Email",
                      })
                    );
                  }}
                />
              </div>
            );
          }
          break;
        case "reminderOpen":
          if (isTemplate) {
            return (
              <div className="w-full">
                <Select
                  styles={customStyles}
                  name="colors"
                  options={sendReminderMenu?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  className="basic-multi-select rounded-[10px]"
                  isSearchable={true}
                  placeholder={value}
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  onChange={(data) => {
                    navigate("/send-reminder");
                    dispatch(
                      setStudent({
                        ...student,
                        templateName:
                          data?.value === "Add new" ? null : data?.value,
                        templateFor: "Send Reminder",
                      })
                    );
                  }}
                />
              </div>
            );
          }
          break;
        case "uniDocs":
          if (isTemplate) {
            return (
              <div className="w-full">
                <Select
                  styles={customStyles}
                  name="colors"
                  options={sendUniDocMenu?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  className="basic-multi-select rounded-[10px]"
                  isSearchable={true}
                  placeholder={value}
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  onChange={(data) => {
                    navigate("/send-uni-doc-reminder");
                    dispatch(
                      setStudent({
                        ...student,
                        templateName:
                          data?.value === "Add new" ? null : data?.value,
                        templateFor: "Send Uni Docs Reminder",
                      })
                    );
                  }}
                />
              </div>
            );
          }
          break;
        case "openDocs":
          return (
            <div className="w-full">
              <Select
                styles={customStyles}
                name="colors"
                options={docs?.map((item) => {
                  return {
                    label: item?.fileName,
                    value: item?.fileName,
                  };
                })}
                className="basic-multi-select rounded-[10px]"
                isSearchable={true}
                placeholder={value}
                classNamePrefix="select"
                menuPortalTarget={document.body}
              />
            </div>
          );
        case "uploadDocs":
          return (
            <div className="w-full">
              <Select
                styles={customStyles}
                name="colors"
                options={docs?.map((item) => {
                  return {
                    label: item?.fileName,
                    value: item?.fileName,
                  };
                })}
                className="basic-multi-select rounded-[10px]"
                isSearchable={true}
                placeholder={value}
                classNamePrefix="select"
                menuPortalTarget={document.body}
              />
            </div>
          );
        // case "openForm":
        //   return (
        //     <div className="w-full">
        //       <Select
        //         styles={customStyles}
        //         name="colors"
        //         options={form?.map((item:any) => {
        //           return {
        //             label: item?.value,
        //             value: item?.value,
        //           };
        //         })}
        //         className="basic-multi-select rounded-[10px]"
        //         isSearchable={true}
        //         placeholder={value}
        //         classNamePrefix="select"
        //         menuPortalTarget={document.body}
        //       />
        //     </div>
        //   );

        default:
          return null;
      }
    },
    [sendEmailMenu, sendReminderMenu, sendUniDocMenu]
  );

  return (
    <>
      <div className="fixed inset-0 z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-xl max-w-[400px] w-[450px] md:w-[550px] lg:mx-0 mx-2 lg:mt-0 px-5">
          <div className="flex justify-between py-4 items-center">
            <h2 className="text-base md:text-xl font-bold text-[#0B0D0F] flex break-words  overflow-hidden mr-4">
              {student?.studentId}
            </h2>
            <img
              src={cross}
              alt="cross"
              className="float-right cursor-pointer w-[35px] md:w-[40px]"
              onClick={handleClose}
            />
          </div>
          <hr />
          <div className="overflow-y-auto max-h-[400px]">
            {menuItems.map((item: any) => {
              return item.hasSubmenu ? (
                <>{renderSubmenu(item.id, item?.value)}</>
              ) : (
                <>
                  <div
                    className="group text-[16px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                    onClick={() => {
                      if (item.route) {
                        if (item.route === "/send-hs-number") {
                          if (isTemplate) {
                          if(sendHsStudentMenu?.length){
                            sendHsStudentMenu?.map((val: string) => {
                              navigate(item.route);
                              dispatch(
                                setStudent({
                                  ...student,
                                  templateFor: "Send HS To Student",
                                })
                              );
                            });
                          }
                          else{
                            navigate(item.route);
                            dispatch(
                              setStudent({
                                ...student,
                                templateFor: "Send HS To Student",
                              })
                            );
                          }
                          }
                        } else {
                          navigate(item.route);
                          dispatch(setStudent(student));
                        }
                      }
                    }}
                  >
                    {item?.id === "call"
                      ? student?.phoneNumber
                        ? `Call ${student?.phoneNumber}`
                        : item.value
                      : item.value}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContextMenuModal;
